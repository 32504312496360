<template>
    <div>
        <div class="banner-container">
            <img class="career-img" src="../../assets/image/career/career-banner.jpg" />
            <p class="join-title">Join Our Team</p>
        </div>
        <el-breadcrumb class="breadcrumb-container" separator="/">
            <el-breadcrumb-item class="breadcrumb-item" :to="{ path: '/career' }">Career</el-breadcrumb-item>
            <el-breadcrumb-item class="breadcrumb-item">Infrastructure Supervisor</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="job-container">
            <p class="title">Infrastructure Supervisor</p>
            <div class="description-container">
                <p class="subtitle"> Job Responsibilities</p>
                <ul class="requirement-list">
                    <li class="list-details"> Maintaining computer networks and systems including software, VPN, router, switch, WIFI, AP, and other physical hardware.</li>
                    <li class="list-details"> Installing, configuring, troubleshooting computer hardware & software and network equipment.</li>
                    <li class="list-details"> To setup server on AWS, managing google workspace, and overall task for infrastructure side.</li>
                    <li class="list-details"> To managing on premise firewall VPNsite2Site, Routing, Polices, VLAN etc.</li>
                    <li class="list-details"> To update any configuration on Bakong system follow NBC guideline.</li>
                    <li class="list-details"> Updating virus protection software and endpoint security to keep data and communications protected.</li>
                    <li class="list-details"> Monitoring computer systems and internet usage to improve network performance.
                        Document all setup and change request of network & infrastructure.</li>
                    <li class="list-details"> Participation with research, planning, scoping, implementation and ongoing infrastructure & application projects.</li>
                    <li class="list-details"> Design & Implementation of new systems and environments.</li>
                    <li class="list-details"> Monitor daily backup status (Database, Server, File Shared etc) and provide timely reports.</li>
                    <li class="list-details"> Maintain server procedure comply from IT Security and Government Team.</li>
                    <li class="list-details"> Monitor system condition and performance.</li>
                    <li class="list-details"> Provides emergency on-call support on a rotation schedule.</li>
                    <li class="list-details"> Other tasks assign by IT manager.</li>
                </ul>
                <p class="salary">SALARY: NEGOTIABLE</p>
                <p class="subtitle">Job Requirements</p>
                <ul class="requirement-list">
                    <li class="list-details"> Bachelor’s Degree in Information Technology or equivalent in related field.</li>
                    <li class="list-details"> Proven at least 5 years experienced or higher with system administration/support.</li>
                    <li class="list-details"> Knowledge of using network equipment such as Fortinet, MikroTik, Cisco, etc.</li>
                    <li class="list-details"> Knowledge of server and SAN, NAS storage, Netapp, Synology, Dell etc.</li>
                    <li class="list-details"> knowledge of Linux, VMware ESXI & Vcenter, AWS, Google Workspace are more advantage.</li>
                    <li class="list-details"> Knowledge of Database (Replicate), Docker service, Microservice etc.</li>
                    <li class="list-details"> Configuration and manage on Webservice like Nginx, Trafik other proxy. Renewal SSL.
                        Knowledge of Google Workspace (Directory, Email,DLP, Shared Drive etc. ) & Microsoft 365 are more advantage.</li>
                    <li class="list-details"> Scripting skill, Cronjob, JSON are more advantage.</li>
                    <li class="list-details"> Experience with design architecture of Bakong System.</li>
                    <li class="list-details"> Ability to keep up with technical innovation and trends in IT system & server support.</li>
                    <li class="list-details"> Good communication in Khmer & English, and Chinese is an advantage.</li>
                    <li class="list-details"> High commitment on tasks assigned and willing to learn new technology.</li>
                    <li class="list-details"> Ability to troubleshoot/debug problems on time and meet with SLA.</li>
                </ul>
            </div>

            <div class="description-container">
                <p class="subtitle">How to Apply</p>
                <p class="requirement-list">Interested candidates please submit your CV/resume with a photo and accompanied by cover letter and any other supporting documents to HR Department:</p>
                <div class="hr-contact">
                    <p class="hr-contact-details">Contact Person : HR Department</p>
                    <p class="hr-contact-details">Email: hr@u-pay.com</p>
                    <p class="hr-contact-details">Contact: 069 931 888 / 077 266 123 </p>
                    <p class="hr-contact-details">Website: www.u-pay.com</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.banner-container {
  position: relative;
  .career-img {
    width: 100%;
  }
  .join-title {
    color: white;
    position: absolute;
    bottom: 3%;
    left: 4%;
    font-size: 2.2rem;
    font-weight: bolder;
  }
}
.job-container {
    display: table;
    margin-right: auto;
    margin-left: auto;
    padding: 3% 25% 3% 25%;
    .title {
        color: #0277E3;
        text-align: center;
        font-size: 1.55rem;
        font-weight: bold;
    }
}

.description-container{
    padding-top: 8%;
    .subtitle {
        color: black;
        text-align: center;
        font-size: 1.35rem;
        font-weight: bold;
    }
    .requirement-list{
        margin: 3% 0% 5% 0%;

        .list-details {
            margin-bottom: 0.5%;
            display: list-item;
            list-style-type: disc;
        }
    }
    .salary{
        font-weight: bolder;
    }

    .hr-contact .hr-contact-details {
        margin-bottom: 3%;
        font-weight: bold;
    }
}
.breadcrumb-container {
    margin : 3% 3%;
}

.breadcrumb-item {
    color: darkgray;
    font-weight: 600;
    font-size: 1.0rem;
}

@media only screen and (max-width: 600px) {
    .job-container {
        display: table;
        margin-right: auto;
        margin-left: auto;
        padding: 3% 5% 3% 5%;
        .title {
            color: #0277E3;
            text-align: center;
            font-size: 1.10rem;
            font-weight: bold;
        }
    }
}
</style>
